.game {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  font-family: "Inter", monospace;

  flex: 1;
  display: flex;
  flex-direction: column;
}

.time-part, .milliseconds span {
  font-variant-numeric: tabular-nums;
}

.top-half {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.bottom-half {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.stopwatch {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.control-buttons {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 60px 30px 0 30px;
  font-size: 20px;
}

.control-buttons button {
  border: none;
  border-radius: 50%;
  padding: 15px 30px;
  font-size: 1em;
  cursor: pointer;
  width: 30vw;
  height: 30vw;
  max-width: 200px;
  max-height: 200px;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.control-buttons button.go {
  background-color: #b8f6a3;
  color: #ffffff;
}

.control-buttons button.stop {
  background-color: #ff7575;
  color: #ffffff;
}

.control-buttons button.init {
  background-color: #dddddd;
  color: #ffffff;
}

.player-records {
  overflow-y: auto;
  margin: 0px;
  font-size: 20px;
}

.player-records ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.player-records li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 5px;
  border-radius: 1px;
  border-bottom: 1px solid #e4dede; 
}

.player-name {
  font-weight: bold;
  flex: 1;
  text-align: left;
}

.last-digit {
  font-weight: bold;
  background: linear-gradient(45deg, #FFD700, #FF8C00);
  -webkit-background-clip: text;
  color: transparent;
}

.last-digit-effect {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 5rem;
  color: black;
  padding: 1rem;
  border-radius: 0.5rem;
  z-index: 1000;
}

@media only screen and (max-width: 600px) {
  .stopwatch {
    font-size: 20vw;
  }
}

@media only screen and (min-width: 601px) {
  .stopwatch {
    font-size: 100px;
  }

  .control-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .control-buttons button {
    width: 150px;
    height: 150px;
    margin: 0px 50px 0px 50px;
    font-size: 1.2em;
  }

  .player-records ul {
    max-width: 425px;
    margin: 0 auto;
    margin-top: 20px;
  }
}